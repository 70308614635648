import { QueryClient } from "@tanstack/react-query";
import {
  BulkActionCellReviewParams,
  CellReview,
  CellReviewState,
  HashCell,
} from "source/api/matrix/types";
import { getCellReviewQueryKey } from "source/api/matrix/useQueryCellReviews";
import { v4 as uuidv4 } from "uuid";
import {
  MatrixGridApi,
  MatrixIRowNode,
} from "source/components/matrix/types/grid.types";

const isCellReviewMatch = (
  cellReview: CellReview,
  cellHash?: string,
  versionedColumnId?: string
) => {
  return (
    cellReview.cell_hash === cellHash &&
    (!versionedColumnId || cellReview.versioned_column_id === versionedColumnId)
  );
};

export const filterCellReviews = (
  cellReviews: CellReview[],
  cellHash?: string,
  versionedColumnId?: string
): CellReview | null => {
  // We do a best effort matching here, mainly by cell_hash. If versioned_column_id exists in cellReviews,
  // we filter that too.
  return (
    cellReviews.find((cellReview) =>
      isCellReviewMatch(cellReview, cellHash, versionedColumnId)
    ) || null
  );
};

// TODO(wilsonaurus): NOTE that this doesn't filter out reviews referring to invalid cells.
// We should filter out reviews pointing to invalid cell hash or invalid cells.
export const calculateCellReviewStats = (
  cellReviews?: CellReview[],
  userId?: string
) => {
  if (!userId) {
    return {
      assignedReviews: 0,
      awaitingTeammatesReviews: 0,
    };
  }
  // reviews assigned to me
  const assignedReviews = (
    (cellReviews || []).filter(
      (cellReview) =>
        cellReview.review_state === CellReviewState.ASSIGNED &&
        cellReview.assignee_user_id === userId
    ) || []
  ).length;

  // awaiting reviews by your teammates
  const awaitingTeammatesReviews = (
    (cellReviews || []).filter(
      (cellReview) =>
        cellReview.review_state === CellReviewState.ASSIGNED &&
        cellReview.assigner_user_id === userId &&
        cellReview.assignee_user_id !== userId // filter out self-assigned reviews
    ) || []
  ).length;

  return {
    assignedReviews,
    awaitingTeammatesReviews,
  };
};

export const getCellReviewFromQueryData = (
  queryClient: QueryClient,
  matrixId?: string,
  cellHash?: string,
  versionedColumnId?: string
): CellReview | null => {
  const cellReviews: CellReview[] | undefined = queryClient.getQueryData(
    getCellReviewQueryKey(matrixId)
  );
  if (!cellReviews) return null;

  return filterCellReviews(cellReviews, cellHash, versionedColumnId);
};

export const clearCellReviewFromQueryData = (
  queryClient: QueryClient,
  matrixId?: string,
  cellHash?: string,
  versionedColumnId?: string
): void => {
  const cellReviews: CellReview[] | undefined = queryClient.getQueryData(
    getCellReviewQueryKey(matrixId)
  );
  if (!cellReviews) return;
  queryClient.setQueryData(
    getCellReviewQueryKey(matrixId),
    cellReviews.filter(
      (cellReview) =>
        !isCellReviewMatch(cellReview, cellHash, versionedColumnId)
    )
  );
};

// common key for hash cells and cell reviews. Can't use the primary key ID
// as it's not present in HashCell.
export const getCommonCellKey = (cell: CellReview | HashCell) =>
  `${cell.cell_hash}-${cell.versioned_column_id}`;

export const optimisticBulkActionToQueryData = (
  queryClient: QueryClient,
  matrixId: string,
  params: BulkActionCellReviewParams
): void => {
  const queryKey = getCellReviewQueryKey(matrixId);
  const hashCellMap = new Map<string, boolean>();

  params.hash_cells.forEach((cell: HashCell) => {
    hashCellMap.set(getCommonCellKey(cell), false);
  });

  queryClient.setQueryData(queryKey, (oldData: CellReview[] | undefined) => {
    if (!oldData) return oldData;
    // optimistically update existing cell reviews.
    const newData = oldData.map((review) => {
      if (hashCellMap.has(getCommonCellKey(review))) {
        hashCellMap.set(getCommonCellKey(review), true);
        const updatedReview = {
          ...review,
          review_state: params.review_state,
          verifier_user_id: params.verifier_user_id,
          verified_at: params.verified_at,
        };
        if (params.review_state !== CellReviewState.VERIFIED) {
          updatedReview["assignee_user_id"] = params.assignee_user_id;
        }
        return updatedReview;
      }

      return review;
    });

    // optimistically create new cell reviews.
    params.hash_cells.forEach((cell) => {
      if (!hashCellMap.get(getCommonCellKey(cell))) {
        newData.push({
          id: uuidv4(), // temporary until onSuccess
          cell_id: "", // temporary until onSuccess
          cell_hash: cell.cell_hash,
          versioned_column_id: cell.versioned_column_id,
          sheet_id: matrixId ?? "",
          review_state: params.review_state,
          verifier_user_id: params.verifier_user_id,
          verified_at: params.verified_at,
          assignee_user_id: params.assignee_user_id,
          is_corrected: false,
          assigner_user_id: "", // temporary until onSuccess
        });
      }
    });
    return newData;
  });
};

export const flashAssignedCellReviews = (
  gridApi: MatrixGridApi,
  cellReviews: CellReview[],
  matrixId: string,
  userId: string
): void => {
  // First, narrow down cell reviews assigned to the user and in this current matrix.
  const assignedReviews = cellReviews.filter(
    (cellReview) =>
      cellReview.review_state === CellReviewState.ASSIGNED &&
      cellReview.assignee_user_id === userId &&
      cellReview.sheet_id === matrixId
  );

  const assignedReviewsMap = new Map<string, CellReview>();
  assignedReviews.forEach((cellReview) => {
    assignedReviewsMap.set(
      `${cellReview.cell_hash}-${cellReview.versioned_column_id}`,
      cellReview
    );
  });

  // Then, for each cell review, find the corresponding cell in the grid and flash it.
  let hasCentered = false;
  gridApi.clearRangeSelection();
  gridApi.forEachNodeAfterFilterAndSort((rowNode: MatrixIRowNode) => {
    Object.values(rowNode.data ?? {})?.forEach((cell) => {
      const assignedReview = assignedReviewsMap.get(
        `${cell?.id}-${cell?.versioned_column_id}`
      );
      if (assignedReview) {
        const cellRowNum = rowNode.rowIndex ?? 0;

        // center the first assigned cell review.
        if (!hasCentered) {
          gridApi.ensureIndexVisible(cellRowNum - 1, "middle");
          gridApi.ensureColumnVisible(cell.static_column_id, "middle");
          gridApi.setFocusedCell(cellRowNum, cell?.static_column_id);
          hasCentered = true;
        }

        // flash and highlight the active cell
        gridApi.flashCells({
          rowNodes: [rowNode],
          columns: [cell?.static_column_id],
          flashDelay: 200,
          fadeDelay: 400,
        });
        gridApi.addCellRange({
          rowStartIndex: cellRowNum,
          rowEndIndex: cellRowNum,
          columnStart: cell?.static_column_id,
          columnEnd: cell?.static_column_id,
        });
      }
    });
  });
};
