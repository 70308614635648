// reports query key factory
import { useMutation, useQuery } from "@tanstack/react-query";
import { QueryContextFromKeys } from "../utils";
import api from "..";
import { queryClient } from "pages/_app";
import { MatrixLeaseResponse } from "./types";
import { AxiosError } from "axios";

// Single reports by reportId are intentionally managed in redux, not with react query.
export const matrixLeaseKeys = {
  all: [{ scope: "matrixLease" }] as const,
  matrix: (matrixId: string) =>
    [{ ...matrixLeaseKeys.all[0], matrixId }] as const,
};

// Typed matrixSession key factory context
// QueryFunctionContext is an object that is passed as argument to the queryFn, this is simply a way of typing it
export type MatrixLeaseQueryContext = QueryContextFromKeys<
  typeof matrixLeaseKeys
>;

export const useQueryMatrixLease = (matrixId: string, enabled = true) =>
  useQuery<MatrixLeaseResponse, AxiosError>({
    queryKey: matrixLeaseKeys.matrix(matrixId),
    queryFn: () => api.reports.getMatrixLease(matrixId),
    refetchInterval: 1000,
    enabled,
    cacheTime: 0,
  });

export const useMutateClaimLease = (
  matrixId: string,
  socketLeaseId: string
) => {
  return useMutation({
    mutationFn: () => api.reports.claimMatrixLease(matrixId, socketLeaseId),
    onSettled: () => {
      queryClient.invalidateQueries(matrixLeaseKeys.matrix(matrixId));
    },
  });
};

export const useMutateRelinquishLease = (
  matrixId: string,
  socketLeaseId: string
) => {
  return useMutation({
    mutationFn: () =>
      api.reports.relinquishMatrixLease(matrixId, socketLeaseId),
    onSettled: () => {
      queryClient.invalidateQueries(matrixLeaseKeys.matrix(matrixId));
    },
  });
};
