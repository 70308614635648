import React, { createContext, useState } from "react";
import { MatrixGridApi } from "../types/grid.types";

type ReportsGridContextValue = {
  gridApi?: MatrixGridApi;
  setGridApi: (api?: MatrixGridApi) => void;
};

export const ReportsGridContext = createContext<ReportsGridContextValue>(
  {} as ReportsGridContextValue
);

type Props = { children: React.ReactNode };

const ReportsGridContextProvider = ({ children }: Props) => {
  const [gridApi, setGridApi] = useState<MatrixGridApi>();

  return (
    <ReportsGridContext.Provider value={{ gridApi, setGridApi }}>
      {children}
    </ReportsGridContext.Provider>
  );
};

export default ReportsGridContextProvider;
