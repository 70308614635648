import api from "..";
import { queryClient } from "pages/_app";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  BulkActionCellReviewParams,
  CellReview,
  CreateCellReviewParams,
  UpdateCellReviewParams,
} from "./types";
import {
  getCommonCellKey,
  optimisticBulkActionToQueryData,
} from "source/utils/matrix/cellReviews";

export const getCellReviewQueryKey = (matrixId?: string) => [
  "cellReviews",
  matrixId,
];

export const useQueryCellReviews = (
  matrixId?: string,
  enableCellReviews?: boolean
) =>
  useQuery({
    queryKey: getCellReviewQueryKey(matrixId),
    queryFn: () => api.reports.getCellReviewsByMatrixId(matrixId ?? ""),
    enabled: !!matrixId && enableCellReviews,
  });

export const useMutationCreateCellReview = (matrixId?: string) =>
  useMutation({
    mutationFn: (params: CreateCellReviewParams): Promise<CellReview> =>
      api.reports.createCellReview(params),
    onMutate: async (params: CreateCellReviewParams) => {
      await queryClient.cancelQueries({
        queryKey: getCellReviewQueryKey(matrixId),
      });
      const previousValue = queryClient.getQueryData(
        getCellReviewQueryKey(matrixId)
      );

      // set to an incomplete, but still usable state as we're awaiting its id.
      // On final settle, we'll update the state with the actual id.
      queryClient.setQueryData(
        getCellReviewQueryKey(matrixId),
        (oldData: (CellReview | CreateCellReviewParams)[] | undefined) => {
          if (!oldData) return [params];
          return [params, ...oldData];
        }
      );
      return { previousValue };
    },
    onError: (err, param: CreateCellReviewParams, context) => {
      queryClient.setQueryData(
        getCellReviewQueryKey(matrixId),
        context?.previousValue
      );
    },
    onSuccess: async (response: CellReview, params: CreateCellReviewParams) => {
      const queryKey = getCellReviewQueryKey(matrixId);

      queryClient.invalidateQueries({ queryKey, refetchType: "none" });
      queryClient.setQueryData(
        getCellReviewQueryKey(matrixId),
        (oldData: CellReview[] | undefined) => {
          if (!oldData) return oldData;
          return oldData.map((cellReview) =>
            cellReview.cell_hash === response.cell_hash ? response : cellReview
          );
        }
      );
      // invalidate key here, but pass in refetchType: "none" to prevent refetching
    },
  });

export const useMutationUpdateCellReview = (matrixId?: string) =>
  useMutation({
    mutationFn: api.reports.updateCellReview,
    onMutate: async (updatedCellReview: UpdateCellReviewParams) => {
      await queryClient.cancelQueries({
        queryKey: getCellReviewQueryKey(matrixId),
      });
      const previousValue = queryClient.getQueryData(
        getCellReviewQueryKey(matrixId)
      );

      // optimistically update
      queryClient.setQueryData(
        getCellReviewQueryKey(matrixId),
        (oldData: (CellReview | UpdateCellReviewParams)[] | undefined) => {
          if (!oldData) return oldData;
          return oldData.map((cellReview) =>
            cellReview.id === updatedCellReview.id
              ? { ...cellReview, ...updatedCellReview }
              : cellReview
          );
        }
      );

      // return context obj with previous value for rollback
      return { previousValue };
    },
    onError: (err, updatedData, context) => {
      queryClient.setQueryData(
        getCellReviewQueryKey(matrixId),
        context?.previousValue
      );
    },
    onSettled: () => {
      // invalidate key here, but pass in refetchType: "none" to prevent refetching
      queryClient.invalidateQueries({
        queryKey: getCellReviewQueryKey(matrixId),
        refetchType: "none",
      });
    },
  });

export const useMutationBulkActionCellReviews = (matrixId?: string) =>
  useMutation({
    mutationFn: api.reports.bulkActionCellReviews,
    onMutate: async (params: BulkActionCellReviewParams) => {
      const queryKey = getCellReviewQueryKey(matrixId);
      await queryClient.cancelQueries({
        queryKey,
      });
      const previousValue = queryClient.getQueryData(
        getCellReviewQueryKey(matrixId)
      );
      if (matrixId) {
        optimisticBulkActionToQueryData(queryClient, matrixId, params);
      }

      return { previousValue };
    },
    onError: (err, params, context) => {
      queryClient.setQueryData(
        getCellReviewQueryKey(matrixId),
        context?.previousValue
      );
    },
    onSuccess: async (response: CellReview[]) => {
      const queryKey = getCellReviewQueryKey(matrixId);

      queryClient.invalidateQueries({ queryKey, refetchType: "none" });
      const upsertedReviews = new Map<string, CellReview>();
      response.forEach((review) => {
        upsertedReviews.set(getCommonCellKey(review), review);
      });
      // only need to update entries as we inserted the new ones in onMutate - these will get
      // updated as well.
      queryClient.setQueryData(
        queryKey,
        (oldData: CellReview[] | undefined) => {
          if (!oldData) return oldData;
          return oldData.map(
            (cellReview) =>
              upsertedReviews.get(getCommonCellKey(cellReview)) || cellReview
          );
        }
      );
    },
  });
